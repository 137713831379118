.wikicard {

    &__cta {

        &-arrow {
            top: 2px;
            transition: transform 0.3s;
            html.using-mouse a:hover &,
            html.using-mouse button:hover & {
                transform: translate(5px, 0);
            }
        }

        svg {
            width: 29px;
            height: 20px;
        }

    }

    &__rating {
        &-star {
            width: 15px;
            height: 15px;

            @screen sp {
                width: 18px;
                height: 18px;
            }

            @screen m {
                width: 20px;
                height: 20px;
            }
        }
        &-suffix {
            width: 10px;
            height: 10px;
            @screen sp {
                width: 12px;
                height: 12px;
            }
        }
        svg {
            width: 100%;
            height: 100%;
        }
    }

    &__related {

        &-toggle[aria-expanded="true"] {
            position: absolute;
            // TODO - WTF
            //#{$this}-title {
            //    opacity: 0;
            //    pointer-events: none;
            //}
            //#{$card}--rich #{$this}--hasimage & {
            //    @media (max-width: theme('screens.sp')) {
            //        z-index: 2;
            //        > * {
            //            background-color: rgba(theme('colors.primary.20'), 0.95);
            //        }
            //    }
            //}
        }

        &-toggle > * {
            transition: background-color 0.3s;
        }

        html.using-mouse &-toggle:hover > *,
        &-toggle:active > * {
            background-color: theme('colors.primary.15');
        }

        &-chevron {
            top: 50%;
            transform: translate(0, -50%);
            right: 15px;
            [aria-expanded="true"] & {
                transform: translate(0, -50%) scaleY(-1);
            }
        }

    }

    html.no-js &:target &__related-content {
        display: block !important;
        height: auto !important;
    }
}
