.slideshow {

    &__slider {
        //#{$block}:not(.js-has-flickity) & {
            //@include scrollableX; // TODO
        //}
    }

    &__counter {
        width: 100px;
    }

    &__slide {

        &-imagewrap {
            width: 100%;
            height: 0;
            padding-bottom: calc((610 / 1060) * 100%);
        }

        &-caption {
            bottom: 0;
            transition: opacity 0.3s;

            [data-component="Slideshow"] & {
                opacity: 0;
            }

            .is-selected & {
                opacity: 1;
                transition: opacity 0.5s 0.5s;
            }
        }

        &--portrait &-image {
            width: auto;
            height: 100%;
            left: 50%;
            transform: translate(-50%, 0);
        }
    }

    &__nextprev {

        width: 44px;
        height: 44px;

        top: 50%;
        transform: translate(0, -50%);

        &--prev {
            left: 0;

            svg {
                transform: scale(-1, 1);
            }

            @screen mp {
                left: calc((36px + theme('gutterMap.mp')) * -1);
            }

            @screen l {
                left: calc((36px + theme('gutterMap.l')) * -1);
            }

            @screen lp {
                left: calc((36px + theme('gutterMap.lp')) * -1);
            }

            @screen xl {
                left: calc((36px + theme('gutterMap.xl')) * -1);
            }
        }

        &--next {
            right: 0;

            @screen mp {
                right: calc((36px + theme('gutterMap.mp')) * -1);
            }

            @screen l {
                right: calc((36px + theme('gutterMap.l')) * -1);
            }

            @screen lp {
                right: calc((36px + theme('gutterMap.lp')) * -1);
            }

            @screen xl {
                right: calc((36px + theme('gutterMap.xl')) * -1);
            }
        }

        svg {
            width: 24px;
            height: 16px;

            @screen mp {
                width: 36px;
                height: 24px;
            }
        }

    }

}
