.testimonials {
    &__nextprev {

        top: 50%;
        transform: translate(0, -50%);
        width: 44px;
        height: 44px;

        &--prev {

            left: -40px;

            @screen s {
                left: -44px;
            }

            @screen m {
                left: -80px;
            }

            @screen l {
                left: -110px;
            }

            @screen lp {
                left: -130px;
            }

            svg {
                transform: scale(-1, 1);
            }
        }

        &--next {
            right: -40px;

            @screen s {
                right: -44px;
            }

            @screen m {
                right: -80px;
            }

            @screen l {
                right: -110px;
            }

            @screen lp {
                right: -130px;
            }
        }

        svg {
            width: 24px;
            height: 16px;

            @screen mp {
                width: 36px;
                height: 24px;
            }
        }

        path {
            fill: currentColor;
        }
    }

    &__slide {
        transition: opacity 0.5s;
        &.is-selected {
            opacity: 1 !important;
            transition: opacity 0.4s 0.3s;
        }
    }

    &[data-component="TestimonialsSlider"] &__slide {
        opacity: 0;
    }

}
