.trip-card {
    &__brandlogo {
        width: 60px;
        max-width: 33%;
        margin: 10px;
        &:not(:last-child) {
            margin-right: -16px;
        }
    }
}
