.homepage-submenu {
    &__inner {
        background: var(--homepage-submenu-bg, theme('colors.secondary.DEFAULT'));
        color: var(--homepage-submenu-color, theme('colors.white.DEFAULT'));
    }
    &__item:not(:last-child) {
        &::after {
            content: "";
            display: block;
            width: 48px;
            height: 1px;
            background-color: currentColor;
            opacity: 0.5;
            position: absolute;
            left: 50%;
            margin: 0 0 0 -24px;
            top: calc(100% - 8px);
            @screen s {
                width: 1px;
                height: 48px;
                left: calc(100% - 1px);
                top: 50%;
                margin: -24px 0 0 0;
            }
        }
    }
}
