.highlights {

    &__slider {
        html.no-js & {
            //@include scrollableX; TODO
        }
    }

    &__icon {
        .is-active & {
            opacity: 0;
        }
        .is-active &.is-filled {
            opacity: 1;
        }
    }
}
