.navbar {

    &__wrapper {
        background-color: theme('colors.white.DEFAULT');
        box-shadow: 0 2px 20px 0 rgb(0 0 0 / 8%);
    }

    &--colored &__wrapper {
        background-color: theme('colors.primary.10');
    }

    //&__inner {
    //
    //}

    &--colored &__inner {
        box-shadow: none;
    }

    &__btn::before {
        content: "";
        display: block;
        width: 50px;
        height: 100%;
        position: absolute;
        top: 0;
        right: 100%;
        background: linear-gradient(90deg, rgba(255, 255, 255, 0) 29%, theme('colors.white.DEFAULT') 100%);
        pointer-events: none;
    }

    &--colored &__btn::before {
        background: linear-gradient(90deg, rgba(231,236,240,0) 29%, rgba(231,236,240,1) 100%);
    }

}
