.hero {

    &__brandlogo {
        max-width: 150px;
        align-self: flex-start;
        @screen mp {
            max-width: 180px;
        }
    }

}

.hero:not(.hero--hasimage) + .content-block:not(.content-block--colored) {
    margin-top: -20px;
    @screen m {
        margin-top: -40px;
    }
}
