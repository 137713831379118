.search {

    min-height: calc(100vh - 80px); // Height of the page-header

    @screen l {
        min-height: calc(100vh - 100px); // Height of the page-header
    }

    &__spinner {
        display: none;
        width: 30px;
        height: 30px;
        opacity: 0;
        transition: opacity 0.3s;
        .js-is-loading & {
            opacity: 1;
            display: block;
        }
        @screen m {
            display: block;
        }
    }

    .modal & {
        width: 100%;
        min-height: inherit;
        background-color: theme('colors.primary.15');
        @screen m {
            background-color: transparent;
        }
    }

    .modal &__closebtn {
        display: block;
        width: 30px;
        height: 30px;
        top: 30px;
        right: 25px;

        @screen m {
            top: 26px;
            right: 40px;
        }

        @screen mp {
            top: 36px;
            right: 40px;
        }

        path {
            stroke: currentColor;
        }
    }

    @screen m {

        .modal &__form {
            order: 1;
            &::before {
                content: "";
                display: block;
                width: 50vw;
                height: 100%;
                position: absolute;
                background-color: inherit;
                top: 0;
                left: 100%;
            }
        }

        .modal &__results {
            order: 0;
            &::before {
                display: none;
            }
            > * {
                position: absolute;
                width: 100%;
                height: 100%;
                top: 0; right: 0;
                overflow: hidden;
                overflow-y: auto;
                -ms-overflow-style: -ms-autohiding-scrollbar;
                -webkit-overflow-scrolling: touch;
            }
        }

        .modal &__results-hits {
            margin-left: auto;
            justify-content: flex-end;
        }
    }

}
