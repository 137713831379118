.text {

    &--columns &__copy {

        @screen mp {

            column-count: 2;
            column-gap: theme('gutterMap.mp');

            *:not(p) {
                display: inline-block;
            }

            p {
                //break-inside: avoid-column;
            }

            h1,h2,h3,h4,h5,h6 {
                break-after: avoid;
                page-break-after: avoid;
                -webkit-column-break-after: avoid;
            }
        }

        @screen l {
            column-count: 2;
            column-gap: theme('gutterMap.l');
        }

        @screen lp {
            column-count: 2;
            column-gap: theme('gutterMap.lp');
        }

        @screen xl {
            column-count: 2;
            column-gap: theme('gutterMap.xl');
        }
    }
}
