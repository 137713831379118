html, body {
    background-color: theme('colors.white.DEFAULT');
    color: theme('colors.primary.DEFAULT');
}

body {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    overflow-anchor: none;
    overflow-x: clip;
    font-family: theme('fontFamily.primary');
    font-size: 16px;
    line-height: calc(22 / 16);
}

::-moz-selection {
    background: theme('colors.primary.DEFAULT');
    color: theme('colors.white.DEFAULT');
}

::selection {
    background: theme('colors.primary.DEFAULT');
    color: theme('colors.white.DEFAULT');
}

.lazyload,
.lazyloading {
    opacity: 0;
    transform: translateZ(0);
}

.lazyloaded {
    @apply transition-opacity duration-300 ease-out-cubic;
}

html.no-outline * {
    outline: none !important;
}

[hidden]:not(:target) {
    display: none !important;
}

input[type="search"], input[type="text"], textarea {
    -webkit-appearance: none;
    border-radius: 0;
    box-sizing: border-box;
}

details,
summary {
    * {
        box-sizing: border-box;
    }
}

details > summary {
    list-style: none;
    cursor: pointer;
}

details > summary::-webkit-details-marker {
    display: none;
}

iframe {
    display: block;
    width: 100%;
    border: none;
    background-color: transparent;
}

button {
    appearance: none;
    cursor: pointer;
}

a, button {
    touch-action: manipulation;
    svg {
        pointer-events: none;
    }
}

blockquote {
    display: block;
    margin: 0;
}

/* Clears the 'X' from Internet Explorer */
input[type=search]::-ms-clear {
    display: none;
    width: 0;
    height: 0;
}

input[type=search]::-ms-reveal {
    display: none;
    width: 0;
    height: 0;
}

/* Clears the 'X' from Chrome */
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
    display: none;
}

// Links
.link--invert {
    text-decoration: none;
    html.using-mouse &:hover,
    html.using-mouse &:active,
    html.using-mouse a:hover &,
    html.using-mouse a:active &,
    html.using-mouse button:hover &,
    html.using-mouse button:active & {
        text-decoration: underline;
    }
}

.no-js img.lazyload[data-src],
.no-js img.lazyload[data-srcset] {
    display: none;
}

// Prevent grid blowout on tiny screens
*:not([data-gridoverlay]) > .grid {
    // Max-width = (outerMargins * 2) + (gutter width * number of columns)
    @media (max-width: calc((theme('gutterMap.default') * theme('gridColumns')) + (theme('outerMarginsMap.default') * 2))) {
        grid-template-columns: minmax(0, 1fr);
    }
}

video[playsinline] {
    pointer-events: none;
}

.link--anim {
    position: relative;

    &::after {
        content: "";
        display: block;
        background-color: currentColor;
        width: 100%;
        height: 0.1em;
        left: 0;
        top: 100%;
        transform: scale(0, 1);
        transform-origin: left center;
        transition: transform 0.3s;
    }

    html.using-mouse &:hover::after,
    html.using-mouse a:hover &::after,
    html.using-mouse button:hover &::after {
        transform: scale(1, 1);
    }
}

.link:not(.link--anim):not(.link--invert) {
    text-decoration: underline;

    html.using-mouse &:hover,
    html.using-mouse &:active,
    html.using-mouse a:hover &,
    html.using-mouse a:active &,
    html.using-mouse button:hover &,
    html.using-mouse button:active & {
        text-decoration: none;
    }
}

.text-balance { // TODO - remove when updating to Tailwind 3.4
    text-wrap: balance;
}
