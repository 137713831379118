.form {

    .modal & {
        max-width: 720px;
        align-self: flex-end;
    }

    &__spinner {
        transition: opacity 0.3s;
        svg {
            width: 40px;
            height: 40px;
        }
        .js-is-loading & {
            opacity: 1;
        }
    }

    .modal & {
        width: 100%;
        min-height: inherit;
    }

    .modal &__closebtn {
        display: block !important;
        width: 30px;
        height: 30px;
        top: 30px;
        right: 25px;

        @screen m {
            top: 26px;
            right: 40px;
        }

        @screen mp {
            top: 36px;
            right: 40px;
        }

        path {
            stroke: currentColor;
        }
    }

    .modal &.js-has-errors &__closebtn {
        color: theme('colors.white.DEFAULT');
    }

    &__form {
        &-content {
            .modal & {
                max-width: 100%;
            }
        }
    }

}
