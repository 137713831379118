.page-nav {

    &__main {

        background: var(--mainmenu-bg, theme('colors.secondary.DEFAULT'));
        color: var(--mainmenu-color, theme('colors.white.DEFAULT'));

        width: calc(100% + (theme('outerMarginsMap.default') * 2));
        margin-left: calc(theme('outerMarginsMap.default') * -1);

        @screen sp {
            width: calc(390px + (theme('outerMarginsMap.sp') * 2));
            margin-left: auto;
            margin-right: calc(theme('outerMarginsMap.sp') * -1);
        }

        @screen m {
            width: calc(390px + (theme('outerMarginsMap.m') * 2));
            margin-right: calc(theme('outerMarginsMap.m') * -1);
        }

        @screen l {
            width: calc(390px + (theme('outerMarginsMap.l') * 2));
            margin-right: calc(theme('outerMarginsMap.l') * -1);
        }

        @media (min-width: 1920px) {
            width: calc(390px + (theme('outerMarginsMap.l') * 2) + (50vw - 960px));
            margin-right: calc((theme('outerMarginsMap.l') * -1) - (50vw - 960px));
        }

    }

    &__item {
        position: relative;

        &::before {
            content: "";
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: 1;
            opacity: 0;
            transition: opacity 0.3s;
            background: linear-gradient(90deg, rgba(255, 255, 255, 0.2) 25%, rgba(255, 255, 255, 0) 95%);
        }

        html.using-mouse &:hover::before,
        &.js-is-active::before {
            opacity: 1;
        }

        @screen mp {
            width: 360px;
            max-width: calc(100% - 80px);
        }
    }

    &__accordion {
        &[aria-expanded="true"] svg {
            transform: scale(1, -1);
        }
    }

    &__social {
        svg {
            &, path, g, circle, rect {
                fill: currentColor !important;
            }
        }
    }
}
