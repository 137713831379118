.pagination {

    a, span {
        height: 34px;
        white-space: nowrap;
        line-height: 30px;
    }

    &__nextprev {

        svg {
            top: 3px;
        }

        html.using-mouse & svg {
            transition: transform 0.3s;
        }

        html.using-mouse &--prev:hover svg {
            transform: translateX(-5px);
        }

        html.using-mouse &--next:hover svg {
            transform: translateX(5px);
        }
    }

    &__current {
        width: 34px;
        height: 34px;
    }

}
