.header {

    height: 80px;

    a {
        color: inherit;
    }

    @screen mp {
        height: 100px;
    }

    &--transparent {
        height: 0;
    }

    &__inner {
        height: 80px;
        background-color: theme('colors.white.DEFAULT');
        color: theme('colors.primary.DEFAULT');
        transition: background-color 0.3s, color 0.3s;

        @screen mp {
            height: 100px;
        }

        [data-component-booted] & {
            position: fixed;
        }
    }

    &--transparent:not(.js-is-scrolled) &__inner {
        background-color: transparent;
        color: theme('colors.white.DEFAULT');
    }

    &__btns {

        background-color: var(--headerbtns-bg, theme('colors.secondary.DEFAULT'));
        color: var(--headerbtns-color, theme('colors.white.DEFAULT'));
        transition: background-color 0.3s, color 0.3s, opacity 0.3s;

        &::before {
            content: "";

            width: 1px;
            height: calc(100% - 35px);
            position: absolute;
            left: -1px;
            top: 15px;
            background-color: transparent;
            transition: background-color 0.3s;
            display: none;

            @screen sp {
                display: block;
            }
            @screen mp {
                height: calc(100% - 45px);
                top: 20px;
            }
        }

        &::after {
            content: "";
            display: block;
            width: 50vw;
            height: 100%;
            position: absolute;
            top: 0; left: 100%;
            background-color: inherit;
            z-index: 0;
            opacity: 0.9999;
            transition: background-color 0.3s;
        }

    }

    &--transparent:not(.js-is-scrolled) &__btns {
        background: transparent !important;
        color: theme('colors.white.DEFAULT') !important;
        &::before {
            background-color: currentColor;
        }
        &::after {
            background-color: transparent;
        }
    }

    &__branding {
        width: 142px;
        max-width: 100%;
        @screen mp {
            width: 180px;
        }
    }

    &__logo {
        pointer-events: none;
        > * {
            pointer-events: auto;
        }
        path {
            transition: fill 0.3s, fill-opacity 0.3s;
        }
    }

    &--transparent:not(.js-is-scrolled) &__logo {
        path {
            fill: currentColor;
        }
        path[fill="#EF5964"] {
            fill-opacity: 0.8;
        }
    }

    &__menu {

        @media (max-width: theme('screens.sp')) {
            display: none;
        }

        &, * {
            -ms-overflow-style: none !important;
            scrollbar-width: none !important;
            overflow: -moz-scrollbars-none !important;
        }

        ::-webkit-scrollbar {
            display: none !important;
        }

    }

    &__menubtn {
        width: 30px;
        height: 22px;
        span span {
            transform-origin: center center;
            transition: transform 0.3s;
        }
        &--close {
            display: none;
        }
    }

    &__accountbtn {
        svg {
            width: 26px;
            height: 26px;
        }
    }

    &__searchbtn {
        cursor: pointer;
        svg {
            width: 24px;
            height: 25px;
            @screen l {
                width: 18px;
                height: 18px;
            }
        }
    }

    &:not([data-component-booted]):target,
    &.js-nav-open {
        z-index: 900;
    }

    &:not([data-component-booted]):target &__inner,
    &.js-nav-open &__inner {
        transform: none !important;
    }

    &:not([data-component-booted]):target &__menubtn--open,
    &.js-nav-open &__menubtn--open {
        display: none;
    }

    &:not([data-component-booted]):target &__menubtn--close,
    &.js-nav-open &__menubtn--close {
        display: block;
    }

    &:not([data-component-booted]):target &__nav,
    &.js-nav-open &__nav,
    &:not([data-component-booted]):target &__overlay,
    &.js-nav-open &__overlay {
        display: block !important;
    }

    &.js-nav-open:not(.js-nav-closing) &__menubtn {
        span span:first-child {
            transform: rotate(45deg) translate(7px, 7px);
        }
        span span:nth-child(2) {
            transform: scaleX(0);
        }
        span span:last-child {
            transform: rotate(-45deg) translate(7px, -7px);
        }
    }

}

.header:not([data-component-booted]) button.header__menubtn {
    display: none;
}

.header[data-component-booted] a.header__menubtn {
    display: none !important;
}
