/** Tailwind **/
@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

/** Fonts, includes, Værsågod reset **/
//@import './base/fonts.pcss';
@import './base/reset.pcss';

/** Custom Tailwind components and utilities */
@import './tailwind/components.pcss';
@import './tailwind/utilities.pcss';

/** Misc styles **/
@import './styles/html.pcss';
@import './styles/wysiwyg.pcss';
@import './styles/flickity.pcss';
@import './styles/pikaday.pcss';

/** BEM **/
@import "./bem/accordion.pcss";
@import "./bem/article-card.pcss";
@import "./bem/badge.pcss";
@import "./bem/button.pcss";
@import "./bem/calendar.pcss";
@import "./bem/calendar-block.pcss";
@import "./bem/calendar-widget.pcss";
@import "./bem/card-big.pcss";
@import "./bem/card-slider.pcss";
@import "./bem/content-block.pcss";
@import "./bem/cta.pcss";
@import "./bem/departure.pcss";
@import "./bem/destinations-hero.pcss";
@import "./bem/extras.pcss";
@import "./bem/form.pcss";
@import "./bem/fitrequestbtn.pcss";
@import "./bem/hero.pcss";
@import "./bem/gallery.pcss";
@import "./bem/homepage-submenu.pcss";
@import "./bem/highlights.pcss";
@import "./bem/listbox.pcss";
@import "./bem/input-accordion.pcss";
@import "./bem/input-checkbox.pcss";
@import "./bem/input-checkbox-deluxe.pcss";
@import "./bem/input-datepicker.pcss";
@import "./bem/input-dropdown.pcss";
@import "./bem/input-lightswitch.pcss";
@import "./bem/input-text.pcss";
@import "./bem/inline-video.pcss";
@import "./bem/intrinsic-image.pcss";
@import "./bem/map.pcss";
@import "./bem/modal.pcss";
@import "./bem/navbar.pcss";
@import "./bem/package.pcss";
@import "./bem/page-content.pcss";
@import "./bem/page-footer.pcss";
@import "./bem/page-header.pcss";
@import "./bem/page-nav.pcss";
@import "./bem/pagination.pcss";
@import "./bem/program.pcss";
@import "./bem/quote.pcss";
@import "./bem/receipt.pcss";
@import "./bem/search.pcss";
@import "./bem/slideshow.pcss";
@import "./bem/testimonials.pcss";
@import "./bem/text.pcss";
@import "./bem/trip-card.pcss";
@import "./bem/video-embed.pcss";
@import "./bem/wiki-card.pcss";
@import "./bem/wiki-feature.pcss";
@import "./bem/whitelabel.pcss";
