.extras {

    &__item {

        &-info {
            border-top: 1px solid rgba(0, 61, 105, 0.15);
            > *:nth-child(2) {
                border-left: 1px solid rgba(0, 61, 105, 0.15);
            }

            @screen mp {
                border-top: none;
                border-left: 1px solid rgba(0, 61, 105, 0.15);
                > *:nth-child(2) {
                    border-left: none;
                    border-top: 1px solid rgba(0, 61, 105, 0.15);
                }
            }
        }
    }
}
