.input-dropdown {

    &--disabled {
        opacity: 0.75;
    }

    select {
        border-radius: 0;
        border: none;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        cursor: pointer;
    }

    &--disabled select {
        cursor: default;
    }

    select::-ms-expand {
        display: none;
    }

    &__chevron {
        right: 20px;
        top: 50%;
        margin-top: -5px;
    }
}
