.content-block:not(.content-block--colored) + .content-block:not(.content-block--colored),
.content-block--colored + .content-block--colored {
    margin-top: -40px;
    @screen m {
        margin-top: -80px;
    }
}

.content-block:not(.content-block--colored) + .content-block:not(.content-block--colored).heading,
.content-block--colored + .content-block--colored.heading {
    margin-top: -40px;
    @screen m {
        margin-top: -40px;
    }
}

.content-block:not(.content-block--colored) + .content-block:not(.content-block--colored).links,
.content-block--colored + .content-block--colored.links {
    margin-top: -40px;
    @screen m {
        margin-top: -40px;
    }
}

.content-block {
    &.heading,
    &.links {
        position: relative;
    }
}

.content-block.content-block--bleed:last-child {
    padding-bottom: 0;
}
