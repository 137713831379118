.calendar {

    &__header {
        background: var(--calendar-filters-bg, theme('colors.secondary.DEFAULT'));
        color: var(--calendar-filters-color, theme('colors.white.DEFAULT'));
    }

    &__spinner {
        opacity: 0;
        background-color: rgba(theme('colors.white.DEFAULT'), 0.5);
    }

    &__searchcta {
        font-size: 16px;
        em {
            color: theme('colors.secondary.DEFAULT');
            font-weight: bold;
            font-size: 24px;
        }
        svg {
            width: 36px;
            height: 24px;
            top: 4px;
            transition: transform 0.3s;
        }
        html.using-mouse &:hover {
            svg {
                transform: translate(5px, 0);
            }
        }
    }
}
