.cta {

    &__overlay {
        transition: opacity 0.3s;
    }

    html.using-mouse & a:hover &__overlay {
        opacity: 0.1;
    }

    html.using-mouse &--bg a:hover &__overlay {
        opacity: 0.7;
    }

}
