.wysiwyg {

    > * {
        margin-bottom: calc((22/16) * 1em);

        &:last-child {
            margin-bottom: 0;
        }
    }

    > * + h1,h2,h3,h4,h5,h6 {
        &:not(:first-child) {
            margin-top: calc((22/16) * 1em);
        }
    }

    h1,h2,h3,h4,h5,h6 {
        margin-bottom: calc((22/16) * 0.5em);
    }

    strong, b {
        font-weight: bold;
    }

    em, i {
        font-style: italic;
    }

    a {
        text-decoration: underline;
        html.using-mouse &:hover {
            text-decoration: none;
        }
    }

    // Lists

    li {
        width: 100%;
        padding-left: calc((22 / 16) * 1em);
        position: relative;
        &:not(:last-child) {
            margin-bottom: 0.5em;
        }
    }

    ul li::before {
        content: "•";
        display: inline-block;
        position: absolute;
        top: 0;
        left: 0;
    }

    ol {
        counter-reset: item;
    }

    ol li::before {
        content: counter(item) ".";
        counter-increment: item;
        display: inline-block;
        position: absolute;
        top: 0;
        left: 0;
    }
}
