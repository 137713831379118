.input-accordion {
    &__chevron {
        right: 20px;
        top: 50%;
        margin-top: -5px;
    }
    &__btn {
        &[aria-expanded="true"] {
            svg {
                transform: scale(1, -1);
            }
        }
    }
    .js-is-selected {
        text-decoration: underline;
    }
    button,a[href] {
        &::before {
            content: "";
            display: block;
            position: absolute;
            top: 0; left: 0;
            width: 100%; height: 100%;
            z-index: 1;
            opacity: 0;
            transition: opacity 0.3s;
            background: linear-gradient(90deg, rgba(theme('colors.primary.DEFAULT'), 0.1) 25%, rgba(theme('colors.primary.DEFAULT'), 0) 95%);
        }
        html.using-mouse &:hover::before {
            opacity: 1;
        }
    }
}
