.input-lightswitch {

    cursor: pointer;

    &__input {
        appearance: none;
        opacity: 0;
        padding: 0;
        margin: 0;
        border: 0;
    }

    html.outline &__input:focus ~ &__label {
        outline: 2px solid Highlight;
        @media (-webkit-min-device-pixel-ratio:0) {
            outline: 5px auto -webkit-focus-ring-color;
        }
    }

    &__pill {
        width: 50px;
        height: 28px;
        border: 2px solid currentColor;
        border-radius: 14px;
        top: 50%;
        margin-top: -14px;
    }

    &--radio &__pill {
        border-radius: 100%;
    }

    &__dot {
        width: 20px;
        height: 20px;
        transition: transform 0.3s, opacity 0.3s;
        opacity: 0.4;
    }

    &__input:checked + &__pill &__dot {
        opacity: 1;
        transform: translate(100%, 0);
    }
}
