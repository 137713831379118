.fitrequestbtn {

    max-width: 772px;
    border-radius: 160px;

    transition: background-color 0.3s;

    svg {
        width: 32px;
        height: 22px;
        transition: transform 0.3s;
    }

    html.using-mouse &:hover,
    &:focus {
        background-color: theme('colors.primary.20');
        svg {
            transform: translate(3px, 0);
        }
    }
}
