.calendar-widget {

    transition: opacity 0.3s;

    @screen mp {
        box-shadow: 5px 5px 10px rgba(theme('colors.black.DEFAULT'), 0.15);
    }

    &__nav {
        @screen mp {
            background-color: #EBF7FF;
            height: 80px;
        }
    }

    &__tab {
        transition: background-color 0.3s, box-shadow 0.3s;
        &[aria-selected="true"] {
            background-color: theme('colors.white.DEFAULT');
            box-shadow: 2px 0 8px rgba(theme('colors.primary.DEFAULT'), 0.15);
            z-index: 10;
        }
        &:not([aria-selected="true"]):active,
        html.using-mouse &:not([aria-selected="true"]):hover {
            background-color: theme('colors.solitude.dark');
        }
    }

    html.no-js &__section {
        &:target {
            display: block;
        }
    }

}
