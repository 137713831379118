.badge {

    text-transform: uppercase !important;

    background: var(--badge-primary-bg, theme('colors.tertiary.DEFAULT'));
    color: var(--badge-primary-color, theme('colors.black.DEFAULT'));

    &--secondary {
        background: var(--badge-secondary-bg, theme('colors.secondary.DEFAULT'));
        color: var(--badge-secondary-color, theme('colors.white.DEFAULT'));
    }
}
