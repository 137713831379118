@layer utilities {

    /**

        Værsågod custom Tailwind utilities
        https://tailwindcss.com/docs/adding-custom-styles#adding-custom-utilities

        These utilities are provided via tailwind.config.js:

        .gpu
        .anti
        .round
        .full
        .center
        .center-x
        .center-y
        .clear
        .scrollable
        .scrollable-x
        .scrollbar-hidden

        .wrapper (container with outer margins and max content width)
        .outer-margins (container with outer margins, but no max-width)
        .gutters (gap-based gutters, both horizontal and vertical)
        .gutters-x (gap-based horizontal gutters)
        .gutters-y (gap-based vertical gutters)
        .pad-wrapper (wrapper meant to be used with pad-gutters children, i.e. like the old .g-wrap)
        .pad-gutters (padding-based gutters, both horizontal and vertical)
        .pad-gutters-x (padding-based horizontal gutters)
        .pad-gutters-y (padding-based vertical gutters)

     */

    .fullheight {
        height: 100vh;
        @supports (--custom:property) {
            height: calc(var(--vh, 1vh) * 100);
        }
    }

    .jsonly {
        html.no-js & {
            display: none !important;
        }
    }

    .nojs {
        html.js & {
            display: none !important;
        }
    }

    .ellipsis {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        max-width: 100%;
    }

    .hit {
        // Note: The element needs position: relative
        &::before {
            content: "";
            display: block;
            width: 100%;
            height: 100%;
            min-width: 44px;
            min-height: 44px;
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
        }
    }

    .clickable {
        transition: var(--transition, transform 0.1s);
        transform-origin: var(--transform-origin, center center);
        &:active,
        a:active &,
        button:active & {
            transform: var(--transform, scale(0.9, 0.9));
            transition: none;
        }
    }

    .text-button {
        color: var(--button-color, theme('colors.white.DEFAULT'));
    }

    .font-button {
        font-weight: var(--button-weight, bold);
    }

    .bg-gradient-primary {
        background: var(--gradient-primary, theme('colors.secondary.DEFAULT'));
    }

    .bg-gradient-secondary {
        background: var(--gradient-secondary, theme('colors.secondary.DEFAULT'));
    }

    .bg-gradient-primary-hover {
        background: var(--gradient-primary-hover, theme('colors.secondary.DEFAULT'));
    }

    .bg-gradient-secondary-hover {
        background: var(--gradient-secondary-hover, theme('colors.secondary.DEFAULT'));
    }

    .filter-white {
        filter: brightness(0) invert(1);
    }

    .text-pretty {
        text-wrap: pretty;
    }

    .text-balance {
        text-wrap: balance;
    }

}
