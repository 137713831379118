.departure {

    padding-bottom: 48px;

    @screen mp {
        padding-bottom: 0;
    }

    &__info {

        @screen mp {
            height: 55px;
            white-space: nowrap;
        }

        &-item {
            border-bottom: 1px solid rgba(0, 61, 105, 0.1);
            &:nth-child(2n+1) {
                border-right: 1px solid rgba(0, 61, 105, 0.1);
            }
            @screen mp {
                border-bottom: none;
                &:nth-child(2n+1) {
                    border-right: none;
                }
            }
        }
    }

    @screen mp {
        &__details {
            padding-left: 10px;
            min-height: 40px;
            white-space: nowrap;
        }

        &--hasflights &__details {
            //width: calc(100% - 180px);
            //margin-left: 180px;
            padding-left: 0;
        }
    }

    &__flights-toggle {
        &-chevron {
            width: 15px;
            height: 8px;
        }
        &[aria-expanded="true"] &-chevron {
            transform: scale(1, -1);
        }
    }

    &__btn {
        @screen mp {
            min-width: 180px;
        }
    }

}
