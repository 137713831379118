.footer {

    a {
        color: inherit;
    }

    &__logo {
        width: 180px;
        max-width: 100%;
        @screen l {
            width: 200px;
        }
    }

    &__links {
        img, svg {
            max-height: 40px;
            width: auto;
        }
    }

    &__assurance {
        img, svg {
            max-height: 55px;
            width: auto;
        }
    }
}
