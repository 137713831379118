.intrinsic-image {
    @supports (--custom:property) {
        &[style*="--ratio"] {
            padding-bottom: calc(100% / (var(--ratio-min))) !important;
            @screen s {
                padding-bottom: calc(100% / (var(--ratio-s))) !important;
            }
            @screen sp {
                padding-bottom: calc(100% / (var(--ratio-sp))) !important;
            }
            @screen m {
                padding-bottom: calc(100% / (var(--ratio-m))) !important;
            }
            @screen mp {
                padding-bottom: calc(100% / (var(--ratio-mp))) !important;
            }
            @screen l {
                padding-bottom: calc(100% / (var(--ratio-l))) !important;
            }
            @screen lp {
                padding-bottom: calc(100% / (var(--ratio-lp))) !important;
            }
            @screen xl {
                padding-bottom: calc(100% / (var(--ratio-xl))) !important;
            }
        }
    }
}
