.accordion {

    html.no-js &:target &__text {
        display: block !important;
    }

    &__icon {
        width: 20px;
        height: 20px;

        span {
            height: 2px;
            top: 9px;
            right: 0;

            &:nth-child(2) {
                transform: rotate(90deg);
            }

        }
    }

    &.js-is-open &__icon span:nth-child(2) {
        transform: rotate(90deg) scaleX(0);
    }

}
