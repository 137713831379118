.quote {
    &__icon {

        width: 68px;
        height: 58px;

        @screen mp {
            width: 86px;
            height: 73px;
        }
    }
}
