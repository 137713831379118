.inline-video {

    video {
        opacity: 0;
    }

    video.js-is-playing,
    &.js-is-playing video {
        opacity: 1;
        transition: opacity 0.5s theme('transitionTimingFunction.in-cubic');
    }

    html.js &:not(.js-cant-play-video) img,
    html.js &.js-is-playing img {
        opacity: 0 !important;
    }
}
