.modal {

    z-index: 999;

    &__spinner {
        opacity: 0;
        transition: opacity 0.3s;
        .js-is-loading & {
            opacity: 1;
        }
    }

    &__content {
        > * {
            width: 100%;
            min-height: inherit;
            @screen m {
                min-height: auto;
            }
        }
    }
}
