.calendar-block {
    & + .content-block--colored,
    .content-block--colored + & {
        margin-top: -40px;
        @screen m {
            margin-top: -80px;
        }
    }
    &:last-child {
        padding-bottom: 0;
    }
}
