.video-embed {

    iframe {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0; left: 0;
    }

    &__poster {
        &-icon {

            transition: transform 0.3s;

            html.using-mouse a:hover & {
                transform: scale(1.1, 1.1);
            }

            svg {
                width: 60px;
                height: 60px;

                @screen sp {
                    width: 80px;
                    height: 80px;
                }

                @screen mp {
                    width: 120px;
                    height: 120px;
                }
            }
        }
    }

}
