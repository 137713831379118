.listbox {
    &__toggle {
        transition: background-color 0.3s, opacity 0.3s;
        &[disabled] {
            opacity: 0.75;
            cursor: default;
        }
    }
    &__options {
        html.no-outline & {
            outline: none !important;
        }
    }
    &__option {
        transition: background-color 0.3s;
        html.outline &[aria-selected] {
            background-color: rgba(theme('colors.white.DEFAULT'), 0.35);
        }
        html.using-mouse.no-outline &:hover {
            background-color: rgba(theme('colors.white.DEFAULT'), 0.35);
        }
    }
    &__check {
        width: 14px;
        height: 10px;
        [aria-selected="true"] & {
            display: block;
        }
    }
}
