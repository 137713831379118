.input-checkbox {

    cursor: pointer;

    &__input {
        appearance: none;
        opacity: 0;
        padding: 0;
        margin: 0;
        border: 0;
    }

    html.outline &__input:focus ~ &__label {
        outline: 2px solid Highlight;
        @media (-webkit-min-device-pixel-ratio:0) {
            outline: 5px auto -webkit-focus-ring-color;
        }
    }

    &__box {
        width: 22px;
        height: 22px;
        border: 2px solid currentColor;
        border-radius: 3px;
        top: 0;
    }

    &--radio &__box {
        border-radius: 100%;
    }

    &__mark {
        width: 13px;
        height: 13px;
        opacity: 0;
        transform: scale(0, 0);
        transform-origin: center center;
        transition: opacity 0.1s 0.05s, transform 0.15s;
    }

    &__input:checked + &__box &__mark {
        opacity: 1;
        transform: scale(1, 1);
        transition: opacity 0.1s 0s, transform 0.15s;
    }

    &--radio &__input:checked + &__box::before {
        content: "";
        display: block;
        width: calc(100% - 8px);
        height: calc(100% - 8px);
        position: absolute;
        top: 4px;
        left: 4px;
        background-color: currentColor;
        border-radius: 100%;
    }
}
