.program {

    > .content-block:first-child {
        padding-top: 20px;
        @screen m {
            padding-top: 40px;
        }
    }

    > .content-block:last-child {
        padding-bottom: 30px;
        @screen m {
            padding-bottom: 60px;
        }
    }

    &:last-child > .content-block:last-child {
        padding-bottom: 0;
    }

    &__entry {

        > * {
            width: calc(100% + (theme('outerMarginsMap.default') * 2));
            margin-left: calc(theme('outerMarginsMap.default') * -1);

            @screen s {
                width: calc(100% + (theme('outerMarginsMap.s') * 2));
                margin-left: calc(theme('outerMarginsMap.s') * -1);
            }

            @screen sp {
                width: calc(100% + (theme('outerMarginsMap.sp') * 2));
                margin-left: calc(theme('outerMarginsMap.sp') * -1);
            }

            @screen m {
                width: calc(100% + (theme('outerMarginsMap.m') * 2));
                margin-left: calc(theme('outerMarginsMap.m') * -1);
            }

            @screen mp {
                width: calc(100% + (theme('outerMarginsMap.mp') * 2));
                margin-left: calc(theme('outerMarginsMap.mp') * -1);
            }

            @screen l {
                width: calc(100% + (theme('outerMarginsMap.l') * 2));
                margin-left: calc(theme('outerMarginsMap.l') * -1);
            }

            @screen lp {
                width: calc(100% + (theme('outerMarginsMap.lp') * 2));
                margin-left: calc(theme('outerMarginsMap.lp') * -1);
            }

            @screen xl {
                width: calc(100% + (theme('outerMarginsMap.xl') * 2));
                margin-left: calc(theme('outerMarginsMap.xl') * -1);
            }
        }

        &-arrow {
            bottom: 26px;
            right: 16px;
            svg {
                width: 16px;
                height: 9px;
            }
            @screen mp {
                bottom: auto;
                top: 50%;
                margin-top: -5px;
                right: 30px;
                svg {
                    width: 21px;
                    height: 12px;
                }
            }
        }

        html.no-js &:target &-arrow,
        [aria-expanded="true"] &-arrow {
            transform: scale(1, -1);
        }

        html.no-js &:target &-content {
            display: block !important;
        }

        .content-block:first-child {
            padding-top: 0px !important;
        }

        .content-block:last-child {
            padding-bottom: 0px !important;
            @screen m {
                padding-bottom: 20px !important;
            }
        }

        .content-block + .content-block {
            padding-top: 20px !important;
            @screen m {
                padding-top: 40px !important;
            }
        }

    }
}
