.btn {

    &--button {
        background: var(--gradient-primary, linear-gradient(88.68deg, theme('colors.secondary.DEFAULT') 0.21%, theme('colors.mandy.DEFAULT') 99.76%));
        color: var(--button-color, theme('colors.white.DEFAULT'));
    }

    &--button::before {
        content: "";
        display: block;
        position: absolute;
        left: 0; top: 0;
        width: 100%;
        height: 100%;
        transition: opacity 0.3s;
        opacity: 0;
        background: var(--gradient-primary-hover, linear-gradient(88.68deg, theme('colors.secondary.DEFAULT') 65%, theme('colors.mandy.DEFAULT') 100%));
    }

    html.using-mouse &--button:hover::before,
    html.using-mouse button:hover &--button::before,
    html.using-mouse a:hover &--button::before {
        opacity: 1;
    }

    &[disabled],
    &--disabled {
        opacity: 0.5;
        cursor: default;
    }

    &[disabled]::before,
    &--disabled::before {
        opacity: 0 !important;
    }

    &--outlined {
        background: transparent;
        &, & > span {
            transition: color 0.3s, background-color 0.3s;
        }
    }
    &--outlined::before {
        background: transparent;
        border: 3px solid currentColor;
        opacity: 1;
        border-radius: inherit;
    }
    &--outlined:hover {
        background: currentColor;
        & > span {
            color: var(--color-primary, #003D69);
        }
    }
}

.btn--button.btn--blue {
    background: var(--gradient-secondary, linear-gradient(88.68deg, theme('colors.primary.DEFAULT') 0.21%, #215E91 99.76%));
    color: var(--button-secondary-color, theme('colors.white.DEFAULT'));
}

.btn--button.btn--blue::before {
    background: var(--gradient-secondary-hover, linear-gradient(88.68deg, theme('colors.primary.DEFAULT') 65%, #215E91 100%));
}

.btn--button.btn--pale {
    background: var(--color-primary-20, #CCD8E1);
    color: var(--color-primary, #003D69);
}

.btn--button.btn--pale::before {
    background: var(--color-primary-25, #BFCED9);
    color: var(--color-primary, #003D69);
}

.btn__arrow {

    width: 36px;
    height: 24px;

    svg {
        transition: transform 0.3s;
    }

    html.using-mouse .btn:hover & svg,
    html.using-mouse button:hover .btn & svg,
    html.using-mouse a:hover .btn & svg {
        transform: translate(10px, 0);
    }

    html.using-mouse .btn--small:hover & svg,
    html.using-mouse button:hover .btn--small & svg,
    html.using-mouse a:hover .btn--small & svg {
        transform: translate(5px, 0);
    }
}

.btn--small .btn__arrow {
    width: 29px;
    height: 20px;
}

.btn[disabled] .btn__arrow svg,
.btn--disabled .btn__arrow svg {
    transform: none !important;
}
