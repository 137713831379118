.card-slider {

    &__navbtn {

        top: 50%;

        width: 50px;
        height: 50px;
        margin-top: -25px;

        transition: transform 0.3s, background-color 0.3s;

        &.js-hidden {
            pointer-events: none;
        }

        html.using-mouse &:hover {
            background-color: theme('colors.secondary.DEFAULT');
        }

        @screen m {
            width: 60px;
            height: 60px;
            margin-top: -30px;
        }

        @screen lp {
            width: 78px;
            height: 78px;
            margin-top: -39px;
        }

        &::before {
            display: block;
            content: "";
            width: 15px;
            height: 100%;
            background-color: inherit;
            position: absolute;
            top: 0;
        }

        svg {
            width: 23px;
            height: 15px;
            @screen m {
                width: 28px;
                height: 19px;
            }
            @screen lp {
                width: 36px;
                height: 24px;
            }
        }

        &--prev {
            left: 0;
            border-top-right-radius: 50%;
            border-bottom-right-radius: 50%;
            svg {
                transform: scaleX(-1);
            }
            &::before {
                left: -15px;
            }

            &.js-hidden {
                transform: translate(-100%, 0) !important;
            }

            html.using-mouse &:hover {
                transform: translate(15px, 0);
            }
        }

        &--next {
            right: 0;
            border-top-left-radius: 50%;
            border-bottom-left-radius: 50%;
            &::before {
                right: -15px;
            }

            &.js-hidden {
                transform: translate(100%, 0) !important;
            }

            html.using-mouse &:hover {
                transform: translate(-15px, 0);
            }
        }
    }

    &:not(.js-has-flickity):not(.js-flickity-active) &__navbtn {
        display: none;
    }

    &__item {
        min-width: 280px;
    }

    &__progress {

        height: calc(2px + theme('gutterMap.default'));

        @screen m {
            height: calc(2px + theme('gutterMap.m'));
        }

        @screen l {
            height: calc(2px + theme('gutterMap.l'));
        }

        &-track {
            background-color: theme('colors.primary.25');
            height: 2px;
            margin-top: auto;
        }

        &-bar {
            background-color: theme('colors.primary.DEFAULT');
            width: 33.3333%;
            height: 100%;
            opacity: 0.65;
        }
    }

    &:not(.js-has-flickity):not(.js-flickity-active) &__progress {
        display: none;
    }

}
