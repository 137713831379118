.wiki-feature--nomargin:not(.content-block--colored) + .wiki-feature:not(.content-block--colored),
.wiki-feature--nomargin.content-block--colored + .wiki-feature.content-block--colored {

    margin-top: -80px !important;

    @screen m {
        margin-top: -160px !important;
    }

}
