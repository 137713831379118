@keyframes pulse {
    0% {
        transform: scale(0.95);
        opacity: 0.7;
        box-shadow: 0 0 0 0 var(--primary-color, theme('colors.black.DEFAULT'));
    }

    70% {
        transform: scale(1);
        opacity: 0;
        box-shadow: 0 0 0 10px var(--primary-color, theme('colors.black.DEFAULT'));
    }

    100% {
        transform: scale(0.95);
        opacity: 0;
        box-shadow: 0 0 0 0 var(--primary-color, theme('colors.black.DEFAULT'));
    }
}

.map {

    &__container {
        padding-bottom: calc((1280/1080) * 100%);
        @screen s {
            padding-bottom: calc((1280/1180) * 100%);
        }
        @screen sp {
            padding-bottom: calc((980/1280) * 100%);
        }
        @screen m {
            padding-bottom: calc((820/1280) * 100%);
        }
        @screen m {
            padding-bottom: calc((720/1280) * 100%);
        }
        @screen l {
            padding-bottom: calc((586/1280) * 100%);
        }

        &-map {
            opacity: 0.001;
        }
    }

    &__marker {

        width: 32px;
        height: 32px;

        &-inner {
            width: 100%;
            height: 100%;
            font-size: 12px;
            line-height: 1;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 100%;
            z-index: 2;
            transition: opacity 0.3s, transform 0.3s;
            svg {
                display: block;
                width: calc(100% - 14px);
                height: calc(100% - 14px);
                position: absolute;
                top: 7px; left: 7px;
                &, path, g, circle, rect {
                    fill: currentColor !important;
                }
            }
        }

        &--route &-inner {
            background-color: var(--primary-color, theme('colors.black.DEFAULT'));
            color: var(--secondary-color, white);
            box-shadow: 0px 0px 1px 0px var(--secondary-color, theme('colors.white.DEFAULT'));
        }

        //&--route.js-is-hidden &-inner {
        //    transform: scale(0.5, 0.5);
        //}

        &--feature &-inner {
            color: var(--primary-color, theme('colors.black.DEFAULT'));
        }

        &--feature.js-is-hidden {
            pointer-events: none;
        }

        &--feature.js-is-hidden &-inner {
            opacity: 0;
        }

        &-pulse {
            width: 100%;
            height: 100%;
            left: 0;
            top: 0;
            background-color: red;
            border-radius: 100%;
            animation: pulse 2s infinite;
        }

        &--feature.js-is-hidden &-pulse {
            display: none;
        }

        &--clickable {
            z-index: 100;
        }
    }

    &__ui {
        &-btn {
            width: 40px;
            height: 40px;
            background-color: var(--primary-color, theme('colors.black.DEFAULT'));
            color: var(--secondary-color, theme('colors.white.DEFAULT'));
            svg {
                width: 26px;
                height: 26px;
                left: 7px;
                top: 7px;
            }
        }
    }

    &__routebtn {
        svg {
            opacity: 0.5;
            &, path, g, circle, rect {
                fill: currentColor !important;
            }
        }
        &-icon {
            width: 24px;
            height: 24px;
            margin-top: -12px;
            padding: 4px;
        }
        &-pulse {
            width: calc(100% + 10px);
            height: calc(100% + 10px);
            animation: pulse 2s infinite;
            margin-top: -5px;
            margin-left: -5px;
            opacity: 0.5;
        }
    }
}

.map-wiki {
    &__close {
        width: 44px;
        height: 44px;
        top: 16px;
        right: 18px;
        svg {
            width: 18px;
            height: 18px;
        }
    }
    &__inner {
        > * {
            -ms-overflow-style: none;  // IE 10+
            scrollbar-width: none;  // Firefox
            &::-webkit-scrollbar {
                display: none;
            }
        }
    }
    &__secondary {
        // border-top: 1px solid rgba(theme('colors.white.DEFAULT'), 0.5); TODO
        &-icon {
            width: 15px;
            height: 15px;
            opacity: 0.85;
        }
        svg {
            position: absolute;
            width: 100%;
            height: 100%;
            top: 1px; left: 0;
            &, path, g, circle, rect {
                fill: currentColor !important;
            }
        }
    }
}
