.flickity-enabled:focus {
    outline: none;
}

.flickity-viewport {
    overflow: visible;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
}

.flickity-slider {
    width: 100%;
    height: 100%;
}

.flickity-prev-next-button {
    display: block;
    text-align: center;
    padding: 0;
    width: 30px;
    height: 100%;
    position: absolute;
    top: 0;
    z-index: 1;
    background-color: transparent;
    cursor: pointer;
    &.next {
        right: 0;
        padding-right: 8px;
    }
    &.previous {
        left: 0;
        padding-left: 8px;
    }
}

.flickity-enabled.is-draggable .flickity-viewport {
    cursor: move;
    cursor: -webkit-grab;
    cursor: grab;
}

.flickity-enabled.is-draggable .flickity-viewport.is-pointer-down {
    cursor: -webkit-grabbing;
    cursor: grabbing;
}

.flickity-scroller {
    width: 70%;
    height: 2px;
    border-radius: 2px;
    background-color: theme('colors.white.DEFAULT');
    position: absolute;
    top: 100%;
    left: 15%;

    div {
        position: absolute;
        top: -1px; left: 0;
        width: 20%;
        height: 4px;
        background-color: theme('colors.black.DEFAULT');
        border-radius: 2px;
    }
}

/* ---- page dots ---- */

.flickity-page-dots {
    position: absolute;
    width: 100%;
    top: 100%;
    padding: 0;
    margin: 20px 0 0;
    list-style: none;
    text-align: center;
    line-height: 1;

    @screen l {
        margin: 30px 0 0;
    }
}

.flickity-rtl .flickity-page-dots { direction: rtl; }

.flickity-page-dots .dot {
    display: inline-block;
    width: 24px;
    height: 24px;
    border-radius: 100%;
    cursor: pointer;
    position: relative;

    &::after {
        content: "";
        display: block;
        width: 16px;
        height: 16px;
        border-radius: 100%;
        background-color: theme('colors.white.DEFAULT');
        border: 1px solid theme('colors.primary.DEFAULT');
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
    }

    &.is-selected::after {
        background-color: theme('colors.primary.DEFAULT');
    }
}
