.input-datepicker {
    
    input {
        cursor: pointer;
    }

    &__icon {
        width: 25px;
        height: 25px;
        top: 50%; right: 8px;
        margin-top: -15px;

        path {
            stroke: currentColor;
        }
    }

}
